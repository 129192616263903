@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sidebar {
  width: 25%;
  height: 100%;
  min-height: 200px;
  overflow: auto;
  position: -webkit-sticky;
  position: sticky;
}

code, pre {
  background-color: #ccc;
  padding: 0 3px;
  border-radius: 5px;
}

.bottom {
  justify-self: bottom;
}

#landing-main {
  /*overflow: hidden;*/
  position: relative;
}

#landing-wrapper {
  position: relative;
  width: 9999px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
}

@media (min-width:320px)  {
  #landingMainImage{
    height:400px;
  }
}
@media (min-width:481px)  {
  #landingMainImage{
    height:400px;
  }
}
@media (min-width:641px)  {
  #landingMainImage{
    height:600px;
  }
}
@media (min-width:961px)  {
  #landingMainImage{
    height:600px;
  }
}
@media (min-width:1025px) {
  #landingMainImage{
    height:600px;
  }
}
@media (min-width:1281px) {
  #landingMainImage{
    height:600px;
  }
}